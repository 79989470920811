import Header from '@/components/(authenticated)/layout/header';
import Sidebar from '@/components/(authenticated)/layout/sidebar';
import { LoadingScreen } from '@/components/loading-screen';
import { UserProvider } from '@/context/current-user-context';
import useAffiliateCode from '@/hooks/use-affiliate-code';
import { cn } from '@/utils';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';
import Styles from './styles.module.css';

export const Route = createFileRoute('/(authenticated)/dashboard/_layout')({
  beforeLoad: async ({ context, location }) => {
    if (!context.auth.isAuthenticated && !context.auth.loading) {
      throw redirect({
        to: '/auth/login',
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }
  },
  pendingComponent: () => <LoadingScreen />,
  component: LayoutComponent,
});

function LayoutComponent(): JSX.Element {
  useAffiliateCode();

  return (
    <UserProvider>
      <div>
        <div
          className={cn(
            Styles.styles,
            'h-full bg-default-gray-50 transition-all duration-300 dark:bg-dark sm:p-5',
          )}
        >
          <div style={{ gridArea: 'header', margin: '0 0 26px 0' }}>
            <Header />
          </div>
          <div
            style={{ gridArea: 'sidebar', margin: '0 26px 0 0' }}
            className="hidden lg:block"
          >
            <Sidebar />
          </div>
          <div
            className="mb-5 px-5 sm:mb-0 sm:px-0"
            style={{ gridArea: 'main', overflowY: 'auto' }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </UserProvider>
  );
}
