import { Badge } from '@/components/base/badge';
import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/base/tooltip';
import { cn } from '@/utils';
import i18n from '@/utils/i18n';
import { UTCDate } from '@date-fns/utc';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type AccountTradingPositionFragment,
  type MonevisAccountTradingDiaryFragment,
  useGetAccountTradingDiaryQuery,
  useGetBrokerAccountQuery,
  useGetClosedPositionsQuery,
} from '@graphql/index';
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
  subHours,
  subMonths,
} from 'date-fns';
import * as locales from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { CountdownTimer } from '../head/components/challenge-card';
import { DetailDrawer } from './components/detail-drawer';

interface DataMapItem {
  profit: number;
  trades: number;
  gain: number;
  lots: number;
}

export function Calendar({
  // diary,
  initialBalance,
  createdAt,
  positions,
}: {
  diary: (MonevisAccountTradingDiaryFragment | null)[];
  initialBalance: number;
  positions: (AccountTradingPositionFragment | null)[];
  createdAt: string;
}): JSX.Element {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today);
  const { t } = useTranslation();
  const intl = useIntl();

  const minGain = 0.5;
  const currentMonth = format(currentDate, 'M');
  const currentYear = format(currentDate, 'yyyy');
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 });
  const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 1 });
  const calendarDays = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd,
  });
  const hideNextArrow =
    Number(currentMonth) - 1 === today.getMonth() &&
    Number(currentYear) === today.getFullYear();

  // const dataMap = useMemo(() => {
  // 	return diary.reduce<
  // 		Record<
  // 			string,
  // 			(MonevisAccountTradingDiaryFragment & { gainPercentage: number }) | null
  // 		>
  // 	>((map, item) => {
  // 		if (item) {
  // 			const dateKey = format(
  // 				new Date(Number(item.year), Number(item.month) - 1, Number(item.day)),
  // 				"yyyy-MM-dd",
  // 			);
  // 			const gainPercentage =
  // 				item.endingBalance && item.startingBalance
  // 					? (item.endingBalance - item.startingBalance) / initialBalance
  // 					: 0;
  // 			if (item.endingBalance !== null && item.endingEquity !== null) {
  // 				map[dateKey] = { ...item, gainPercentage };
  // 			}
  // 		}
  // 		return map;
  // 	}, {});
  // }, [diary, currentYear, currentMonth, initialBalance]);

  const positionsMap = useMemo(() => {
    return positions.reduce<Record<string, DataMapItem>>((map, item) => {
      if (item) {
        let rawDate = new UTCDate(String(item.dateClose));
        rawDate = subHours(rawDate, 2);
        // const utcDate = fromZonedTime(String(item.dateClose), "EAT");
        let originalDate = rawDate;
        // originalDate.setTime(originalDate.getTime() - 2 * 60 * 60 * 1000);
        // console.log(item.dateClose);
        // console.log(originalDate);
        // originalDate = subHours(originalDate, 2);
        // console.log(originalDate);

        if (originalDate.getHours() >= 22 && originalDate.getMinutes() >= 5) {
          originalDate = addDays(originalDate, 1);
        }

        const dateKey = format(originalDate, 'yyyy-MM-dd');
        if (map[dateKey]) {
          const i = map[dateKey];
          i.profit += Number(item.profit);
          i.trades += 1;
          i.gain = Number(
            Number(Number(i.profit / initialBalance) * 100).toFixed(4),
          );
          i.lots += Number(item.lots);
        } else {
          const i: DataMapItem = {
            profit: Number(item.profit),
            trades: 1,
            gain: Number(
              Number(Number((item.profit ?? 0) / initialBalance) * 100).toFixed(
                4,
              ),
            ),
            lots: Number(item.lots),
          };
          map[dateKey] = i;
        }
      }

      return map;
    }, {});
  }, [positions, currentYear, currentMonth, initialBalance]);

  function handlePrevMonth(): void {
    setCurrentDate(subMonths(currentDate, 1));
  }

  function handleNextMonth(): void {
    setCurrentDate(addMonths(currentDate, 1));
  }

  function getWeekdays(): string[] {
    const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const weekdays = [];

    for (let i = 0; i < 7; i++) {
      const weekday = format(addDays(startWeek, i), 'E', {
        locale: (locales as Record<string, locales.Locale | undefined>)[
          i18n.language
        ],
      });
      weekdays.push(
        intl.formatMessage({ id: weekday, defaultMessage: weekday }),
      );
    }
    return weekdays;
  }

  return (
    <Section variant="secondary">
      <div className="w-full text-default-gray-900 dark:text-white">
        <div className="mb-2 flex w-full justify-between gap-4 py-2">
          <div className="flex gap-4">
            <div className="flex items-center gap-1">
              <FontAwesomeIcon
                className="h-4 cursor-pointer"
                icon={faCircleArrowLeft}
                onClick={handlePrevMonth}
              />
              <span>{format(currentDate, 'MMMM')}</span>
              <span>{currentYear}</span>
              {!hideNextArrow && (
                <FontAwesomeIcon
                  className="h-4 cursor-pointer"
                  icon={faCircleArrowRight}
                  onClick={handleNextMonth}
                />
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-7 divide-default-gray-100 border-default-gray-100 dark:divide-default-gray-800 dark:border-default-gray-800 md:divide-x md:divide-y md:border-y md:border-r">
          <div className="col-span-7 grid grid-cols-7 divide-default-gray-100 border-default-gray-100 text-sm dark:divide-default-gray-800 dark:border-default-gray-800 md:divide-x md:border-l">
            {getWeekdays().map((weekday) => (
              <div key={weekday} className="p-2 text-center font-medium">
                {weekday}
              </div>
            ))}
          </div>
          {calendarDays.map((date) => {
            const formattedDate = format(date, 'yyyy-MM-dd');
            const diaryData = positionsMap[formattedDate];
            const isToday = isSameDay(date, today);
            const isInThisMonth = date.getMonth() === currentDate.getMonth();
            const profit = diaryData?.profit ?? 0;
            const isAfterToday = isAfter(date, today);
            const isAfterCreation = isAfter(date, parseISO(createdAt));
            // const profit = diaryData
            // 	? (diaryData.endingBalance ?? 0) -
            // 		(diaryData.startingBalance ?? 0)
            // 	: 0;
            const hasTrades =
              diaryData && (Boolean(diaryData.trades) || Math.abs(profit) > 0);

            const createdAtDate = parseISO(createdAt);

            const objectDate = new Date();
            // Number.parseInt(diaryData?.year?.toString() ?? "0", 10),
            // Number.parseInt(diaryData?.month?.toString() ?? "0", 10) - 1,
            // Number.parseInt(diaryData?.day?.toString() ?? "0", 10) + 1,
            const gainPercentage = diaryData?.gain ?? 0;

            const isInOrAfterThisMonth = !isBefore(objectDate, createdAtDate);

            return (
              <DetailDrawer
                profit={profit}
                lots={diaryData?.lots ?? 0}
                trades={diaryData?.trades ?? 0}
                key={formattedDate}
                gainPercentage={gainPercentage / 100}
                noTrades={!hasTrades && isInOrAfterThisMonth && isInThisMonth}
              >
                <div
                  className={cn(
                    'relative flex h-14 w-full flex-col justify-between p-2 text-default-gray-700 dark:bg-default-gray-950 md:h-28',
                    {
                      'bg-default-gray-50 text-default-gray-300 dark:bg-default-gray-500/5':
                        !isInThisMonth,
                    },
                    {
                      'bg-danger/10 dark:bg-danger/20':
                        gainPercentage &&
                        gainPercentage < 0 &&
                        hasTrades &&
                        isInThisMonth,
                    },
                    {
                      'bg-default-green-700/10 dark:bg-default-green-700/20':
                        gainPercentage &&
                        gainPercentage > minGain &&
                        hasTrades &&
                        isInThisMonth,
                    },
                    {
                      'bg-default-gray-300/20 dark:bg-default-gray-500/20':
                        !hasTrades &&
                        isInOrAfterThisMonth &&
                        isInThisMonth &&
                        isAfterCreation &&
                        !isAfterToday,
                    },
                  )}
                >
                  <div className="flex w-full justify-center text-sm md:justify-end">
                    <span
                      className={cn(
                        'flex h-6 min-w-6 items-center justify-center text-default-gray-400 dark:text-default-gray-300',
                        {
                          'rounded-full bg-black font-medium text-white dark:bg-white dark:text-default-gray-950':
                            isToday,
                        },
                      )}
                    >
                      {format(date, 'd')}
                    </span>
                  </div>
                  {isToday && (
                    <div
                      data-testid="restart-countdown"
                      className="absolute left-2 top-2 hidden md:flex"
                    >
                      <Badge className="px-2 py-1" variant="yellow">
                        <CountdownTimer />
                      </Badge>
                    </div>
                  )}
                  {!hasTrades &&
                    isInOrAfterThisMonth &&
                    isInThisMonth &&
                    !isAfterToday &&
                    isAfterCreation && (
                      <span className="hidden text-xs md:block ">
                        {t('accountDiary.noTrades')}
                      </span>
                    )}
                  {hasTrades && (
                    <div
                      className={cn(
                        'flex flex-col text-default-gray-400 dark:text-white md:flex',
                        {
                          'text-default-gray-400 dark:text-default-gray-500':
                            gainPercentage &&
                            gainPercentage < minGain &&
                            gainPercentage > 0,
                        },
                      )}
                    >
                      <span
                        className={cn(
                          'hidden self-end font-medium text-default-gray-600 md:inline-flex',
                          {
                            'text-danger': gainPercentage && gainPercentage < 0,
                          },
                          {
                            'text-default-green-700 dark:text-default-green-700':
                              gainPercentage && gainPercentage > minGain,
                          },
                        )}
                      >
                        {intl.formatNumber(profit, {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </span>
                      <span className="hidden self-end text-xs opacity-50 md:inline-flex">
                        {t('accountDiary.trades')} {diaryData.trades}
                      </span>

                      <div className="mt-px flex w-full items-end justify-end gap-1">
                        {gainPercentage &&
                          gainPercentage < minGain &&
                          gainPercentage > 0 && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <div className="flex items-center gap-2 text-default-gray-500">
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>{t('accountDiary.tooltip')}</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        <span className="self-end text-[9px] opacity-50 md:text-xs">
                          {intl.formatNumber(diaryData.gain / 100, {
                            style: 'percent',
                            maximumFractionDigits: 3,
                          })}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </DetailDrawer>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

export function CalendarWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data: accountDiary, loading } = useGetAccountTradingDiaryQuery({
    variables: { login: accountId },
  });

  const { data: positions, loading: positionsLoading } =
    useGetClosedPositionsQuery({
      variables: { login: accountId },
      fetchPolicy: 'cache-and-network',
    });

  const { data: brokerAccount, loading: brokerAccountLoading } =
    useGetBrokerAccountQuery({
      variables: { id: accountId },
      fetchPolicy: 'cache-and-network',
    });

  if (loading || brokerAccountLoading || positionsLoading) {
    return (
      <div className="h-40 w-full">
        <Skeleton className="h-full w-full rounded-2xl" />
      </div>
    );
  }

  invariant(
    accountDiary?.accountTradingDiary,
    `[${accountId}] Diary is missing`,
  );
  invariant(
    brokerAccount?.brokerAccount,
    `[${accountId}] Broker account is missing`,
  );
  invariant(
    positions?.closedPositions,
    `[${accountId}] Closed positions are missing`,
  );

  const sortedDiary = [...(accountDiary?.accountTradingDiary ?? [])].sort(
    (a, b) => {
      const { day: dayA, month: monthA, year: yearA } = a ?? {};
      const { day: dayB, month: monthB, year: yearB } = b ?? {};
      const dateA = new Date(Number(yearA), Number(monthA) - 1, Number(dayA));
      const dateB = new Date(Number(yearB), Number(monthB) - 1, Number(dayB));
      return dateB.getTime() - dateA.getTime();
    },
  );

  return (
    <Calendar
      diary={sortedDiary}
      positions={positions.closedPositions}
      initialBalance={brokerAccount.brokerAccount.initialBalance}
      createdAt={brokerAccount.brokerAccount.createdAt}
    />
  );
}
