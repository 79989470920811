import { Loading } from '@/components/base/loading';
import { PageHeading } from '@/components/base/page-heading';
import { Trans, useTranslation } from 'react-i18next';

export function AccountPending(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 text-center">
      <div className="flex flex-col gap-2">
        <PageHeading>
          {t('orderDetailPage.creationInProgress.title')}
        </PageHeading>
        <span>
          <Trans
            i18nKey="orderDetailPage.creationInProgress.delayInfo"
            components={{
              bold: <span className="font-extrabold" />,
            }}
          />
        </span>
        <Loading className="py-2" size="big" />
        <span>{t('orderDetailPage.creationInProgress.delayInfo2')}</span>

        <span>{t('orderDetailPage.creationInProgress.delayInfo3')}</span>
      </div>
    </div>
  );
}
