import { Button } from '@/components/base/button';
import Section from '@/components/base/section';
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

export function JoinSocialsSection({
  children,
}: PropsWithChildren): JSX.Element {
  const { t } = useTranslation();
  return (
    <Section variant="secondary">
      <div className="flex w-full flex-col items-center justify-between gap-6 text-center sm:flex-row sm:text-start">
        <div className="w-full">{children}</div>
        <div className="flex flex-col items-center justify-center gap-3 text-white sm:flex-nowrap sm:justify-end sm:gap-6 md:flex-row">
          <Button
            target="_blank"
            rel="noopener noreferrer"
            as="link"
            href="https://discord.gg/DHpntuyaqH"
            className="w-full sm:w-fit"
          >
            <span>{t('joinSocials.discord')}</span>
            <FontAwesomeIcon className="h-6 w-6" icon={faDiscord} />
          </Button>

          <div className="flex gap-3 md:gap-6">
            <span className="text-default-gray-950 dark:text-white">
              {t('joinSocials.or')}
            </span>

            <div className="flex gap-4">
              <a
                href="https://www.facebook.com/monevis.trading"
                rel="noopener"
                target="_blank"
                title="Join Facebook"
              >
                <FontAwesomeIcon
                  className="cursor-pointer text-default-gray-950 dark:text-white"
                  fixedWidth
                  icon={faFacebook}
                />
              </a>
              <a
                href="https://twitter.com/moneviscom"
                rel="noopener"
                target="_blank"
                title="Join Twitter"
              >
                <FontAwesomeIcon
                  className="cursor-pointer text-default-gray-950 dark:text-white"
                  fixedWidth
                  icon={faXTwitter}
                />
              </a>
              <a
                href="https://www.instagram.com/moneviscom"
                rel="noopener"
                target="_blank"
                title="Join Instagram"
              >
                <FontAwesomeIcon
                  className="cursor-pointer text-default-gray-950 dark:text-white"
                  fixedWidth
                  icon={faInstagram}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
