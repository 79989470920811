import { EventName, useAnalytics } from '@/context/analytics-context';
import { CredentialsContext } from '@/context/credentials-context';
import { useCurrentUser } from '@/context/current-user-context';
import {
  AddonType,
  OrderStatus,
  PaymentMethod,
  useGetOrderQuery,
  useRequestPaymentForOrderMutation,
  type AddonFragment,
  type GetOrderQueryVariables,
  type OrderWithIntentFragment,
  type RequestPaymentForOrderMutationVariables,
} from '@graphql/index';
import { useNavigate } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { PageHeading } from '../base/page-heading';
import Section from '../base/section';
import { AccountPending } from './components/account-pending';
import { Badge } from './components/badge';
import { BridgerCheckout } from './components/bridger-checkout';
import { ConfirmoCheckout } from './components/confirmo-checkout';
import { OrderSummary } from './components/order-summary';
import { TrustBox } from './components/trustbox';

function AddonsRender({ addons }: { addons: AddonFragment[] }): JSX.Element {
  const { t } = useTranslation();
  const leverage = addons.find((a) => a.type === AddonType.IncreasedLeverage)
    ? 75
    : 50;
  const split = addons.find((a) => a.type === AddonType.IncreasedProfitSplit)
    ? 85
    : 75;

  const payouts = addons.find((a) => a.type === AddonType.WeeklyPayouts)
    ? 7
    : 14;

  return (
    <div className="flex gap-2">
      <Badge>{t('addonsRender.leverage', { leverage })}</Badge>
      <Badge>{t('addonsRender.profitSplit', { split })}</Badge>
      <Badge>{t('addonsRender.payouts', { payouts })}</Badge>
    </div>
  );
}

enum Mode {
  CHECKOUT,
  SUMMARY,
  LAODING,
}

export function OrderDetailPage({
  orderId,
  hash,
  status,
}: {
  orderId: string;
  hash?: string;
  status?: string;
}): JSX.Element {
  const [requestPaymentMutation] = useRequestPaymentForOrderMutation();
  const [mode, setMode] = useState(Mode.SUMMARY);
  const [gateway, setGateway] = useState<PaymentMethod | null>();
  const [gatewayId, setGatewayId] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { track, identify } = useAnalytics();
  const navigate = useNavigate();
  const { setToken } = useContext(CredentialsContext);
  const user = useCurrentUser();
  const { t } = useTranslation();

  let defaultParams:
    | GetOrderQueryVariables
    | RequestPaymentForOrderMutationVariables = {
    orderNumber: orderId,
  };

  if (hash) {
    defaultParams = {
      ...defaultParams,
      secureHash: hash,
    };
  }

  //order expired error
  const { data } = useGetOrderQuery({
    variables: {
      ...defaultParams,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: status === 'declined' ? 1500 : 15000,
    onError: async () => {
      navigate({ to: '/auth/login' });
      const message = t('orderDetailPage.error');
      enqueueSnackbar(message, {
        preventDuplicate: true,
        variant: 'error',
      });
    },
    skip: !orderId,
  });

  const initialBalance = intl.formatNumber(data?.order.initialBalance ?? 0, {
    style: 'currency',
    currency: 'USD',
  });

  function callGateway(order: OrderWithIntentFragment, _force = false): void {
    if (order?.user?.id) {
      identify(String(order.user.id), {
        email: String(order.user.email),
        first_name: order.user.firstName,
        last_name: order.user.lastName,
      });
    }

    if (order.paymentIntent.gatewayUrl) {
      if (order.paymentIntent.gateway === PaymentMethod.Confirmo) {
        track(EventName.InitiatedGateway, { type: 'CONFIRMO' });
        setGateway(PaymentMethod.Confirmo);
        setGatewayId(order.paymentIntent.gatewayUrl);
      }
      if (order.paymentIntent.gateway === PaymentMethod.Stripe) {
        track(EventName.InitiatedGateway, { type: 'CARD' });
        setMode(Mode.CHECKOUT);
        setGateway(PaymentMethod.Stripe);
        setGatewayId(order.paymentIntent.gatewayUrl);
      }
    }
  }

  async function requestPayment(paymentMethod: PaymentMethod): Promise<void> {
    setLoading(true);
    try {
      // setShowBridge(undefined);
      const response = await requestPaymentMutation({
        variables: {
          ...defaultParams,
          paymentMethod,
          theme: 'bright',
        },
      });

      if (response.data?.requestPaymentForOrder.paymentIntent.id) {
        callGateway(
          response.data.requestPaymentForOrder as OrderWithIntentFragment,
          true,
        );
      }
    } catch (e) {
      setMode(Mode.SUMMARY);
      setGateway(null);
      setGatewayId(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (data?.order.status === OrderStatus.Paid) {
      setMode(Mode.LAODING);
    }
    if (data?.order.status === OrderStatus.Completed) {
      track(EventName.Purchase, {
        value: Number(data?.order.total),
        currency: data.order.currency ?? 'USD',
        id: String(data.order.orderNumber),
        items: [
          {
            id: `Size:${data.order.initialBalance}`,
            name: `Size:${data.order.initialBalance}`,
          },
        ],
      });
      navigate({
        to: `/dashboard/accounts/detail/$account-id`,
        params: {
          'account-id': data?.order.brokerAccount?.login ?? '',
        },
      });
    }

    if (data?.order.user?.token && !user?.me) {
      setToken(data?.order.user?.token);
    }
  }, [data, navigate, data?.order, track, setToken, user]);

  useEffect(() => {
    if (status) {
      if (status === 'declined') {
        if (data?.order.id) {
          track(EventName.FailedPurchase, {
            number: String(data?.order.orderNumber),
            country: String(data?.order.address?.countryCode),
            email: String(data?.order.address?.email),
            amount: Number(data?.order.total),
          });
        }
        enqueueSnackbar({
          message: 'There was an error while processing your payment',
          variant: 'error',
        });
      }
    }
  }, [status, data?.order, track]);

  return (
    <Section variant="secondary" className="mx-auto flex max-w-4xl">
      {/* <AccountDetails accountId={data?.order.brokerAccount?.login} /> */}
      <div className="flex w-full flex-col gap-2">
        <div>
          <PageHeading
            subheading={t('orderDetailPage.initialBalance', { initialBalance })}
            className="text-center"
          >
            {t('orderDetailPage.title')}
          </PageHeading>
        </div>

        <div className="flex justify-center">
          <AddonsRender
            addons={(data?.order.addons as AddonFragment[]) ?? []}
          />
        </div>
        <div className="mt-4 flex w-full justify-center">
          {mode === Mode.SUMMARY && (
            <OrderSummary
              loading={loading}
              subTotal={data?.order.subTotal}
              total={data?.order.total}
              currency={data?.order.currency ?? 'USD'}
              onSubmit={(method) => {
                requestPayment(method);
              }}
            />
          )}
          {mode === Mode.CHECKOUT && (
            <div className="flex w-full flex-col gap-4">
              {gateway === PaymentMethod.Stripe && (
                <BridgerCheckout
                  token={String(gatewayId)}
                  onBack={() => {
                    setMode(Mode.SUMMARY);
                    setGateway(null);
                    setGatewayId(null);
                  }}
                />
              )}

              <TrustBox />
            </div>
          )}
          {gateway === PaymentMethod.Confirmo && (
            <ConfirmoCheckout token={String(gatewayId)} />
          )}
          {mode === Mode.LAODING && <AccountPending />}
        </div>
      </div>
    </Section>
  );
}
