import { useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const AFFILIATE_CODE_KEY = 'affiliateCode';

type UseAffiliateCodeHook = () => {
  affiliateCode: string | null;
  setAffiliateCode: (code: string) => void;
};

const useAffiliateCode: UseAffiliateCodeHook = () => {
  const { search } = useRouter().latestLocation;
  const [cookie] = useCookies(['_fprom_ref']);

  useEffect(() => {
    const urlRef = search.ref;
    if (urlRef) {
      localStorage.setItem(AFFILIATE_CODE_KEY, urlRef);
    }
  }, [search]);

  const getCode = (): string | null => {
    if (localStorage.getItem(AFFILIATE_CODE_KEY)) {
      return localStorage.getItem(AFFILIATE_CODE_KEY);
    } else if (cookie._fprom_ref) {
      return cookie._fprom_ref as string;
    }
    return null;
  };

  const affiliateCode = getCode();

  const setAffiliateCode = (code: string): void => {
    localStorage.setItem(AFFILIATE_CODE_KEY, code);
  };

  return { affiliateCode, setAffiliateCode };
};

export default useAffiliateCode;
