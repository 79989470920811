import { Input } from '@/components/base/form/input';
import { TextField } from '@/components/base/form/text-field';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AffiliateCode({
  affiliateCode,
  onChange = () => false,
}: {
  affiliateCode: string | null | undefined;
  onChange?: (value: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | null | undefined>(
    affiliateCode ?? '',
  );

  return (
    <fieldset className="col-span-2 md:col-span-1">
      <TextField label={t('orderPage.affiliateCode')}>
        <Input
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          inputSize="sm"
          variant="secondary"
          value={value?.toUpperCase()}
          readOnly={Boolean(affiliateCode)}
        />
      </TextField>
    </fieldset>
  );
}
