import { Drawer } from '@/components/base/drawer';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/base/popover';
import { useScreenSize } from '@/hooks/use-screen-size';
import { cn } from '@/utils';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

export function DetailDrawer({
  children,
  profit,
  lots,
  trades,
  gainPercentage,
  noTrades,
}: {
  children: ReactNode;
  profit: number;
  lots: number;
  trades: number;
  gainPercentage: number;
  noTrades: boolean;
}): JSX.Element {
  const intl = useIntl();
  const { isMedium } = useScreenSize();
  const { t } = useTranslation();
  const minGain = 1;

  return (
    <Drawer disabled={isMedium} trigger={children}>
      <>
        <span className="self-center text-lg font-medium">
          {t('accountDiary.detail')}
        </span>
        <div className="divide-y divide-default-gray-400 dark:divide-default-gray-600">
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.profit')}</span>
            <span
              className={cn(
                'text-lg',
                { 'text-danger': profit < 0 },
                {
                  'text-default-green-700':
                    profit > 0 && gainPercentage > minGain,
                },
              )}
            >
              {intl.formatNumber(profit, {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.lots')}</span>
            <span>{lots}</span>
          </div>
          <div className="flex w-full justify-between py-2">
            <span>{t('accountDiary.trades')}</span>
            <span>{trades}</span>
          </div>
          <div className="flex w-full justify-between gap-1 py-2">
            <span>{t('accountDiary.gainPercentage')}</span>
            <div className="flex items-center gap-1">
              {gainPercentage &&
                gainPercentage < minGain &&
                gainPercentage > 0 && (
                  <Popover>
                    <PopoverTrigger asChild>
                      <button
                        type="button"
                        className="flex items-center gap-2 p-1 text-default-gray-600"
                      >
                        <FontAwesomeIcon
                          className="h-5 w-5"
                          icon={faCircleExclamation}
                        />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md border border-slate-200 bg-white px-3 py-1.5 text-sm text-slate-950 shadow-md dark:border-default-gray-800 dark:bg-default-gray-950 dark:text-slate-50">
                      <p>{t('accountDiary.tooltip')}</p>
                    </PopoverContent>
                  </Popover>
                )}
              <span>
                {intl.formatNumber(gainPercentage, {
                  style: 'percent',
                  maximumFractionDigits: 3,
                })}
              </span>
            </div>
          </div>
          {noTrades && (
            <div className="flex w-full justify-center py-2">
              <span>{t('accountDiary.noTrades')}</span>
            </div>
          )}
        </div>
      </>
    </Drawer>
  );
}

/*
<Drawer trigger={
children
}>
<>
 <span className="self-center text-lg font-medium">
            {t('accountDiary.detail')}
          </span>
          <div className="divide-y divide-default-gray-400 dark:divide-default-gray-600">
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.profit')}</span>
              <span
                className={cn(
                  'text-lg',
                  { 'text-danger': profit < 0 },
                  { 'text-default-green-700': profit > 0 },
                )}
              >
                {intl.formatNumber(profit, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.lots')}</span>
              <span>{lots}</span>
            </div>
            <div className="flex w-full justify-between py-2">
              <span>{t('accountDiary.trades')}</span>
              <span>{trades}</span>
            </div>
          </div>
</>
</Drawer>
*/
